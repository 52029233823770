<template>
  <div>
    <t-dropdown variant="smActions">
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          class="text-oDark focus:outline-none"
          aria-label="More Actions Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <i class="text-sm text-gray-600 fas fa-ellipsis-v" />
        </button>
      </template>

      <template #default="{ hide }">
        <div
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md z-200"
        >
          <t-dropdown-item
            class="font-medium"
            @click="
              executeAction('updateAccountStatus', {
                action: data.user_status === 'A' ? 'D' : 'A',
              })
            "
          >
            {{
              data.user_status === 'A'
                ? $t(
                    'components.organizationUsersManagement.table.action.deactivate'
                  )
                : $t(
                    'components.organizationUsersManagement.table.action.activate'
                  )
            }}
          </t-dropdown-item>

          <t-dropdown-item
            class="font-medium"
            @click="
              executeAction('showUpdateOrResetPassword', { action: 'change' })
            "
          >
            {{
              $t(
                'components.organizationUsersManagement.table.action.changePassword'
              )
            }}
          </t-dropdown-item>

          <t-dropdown-item
            class="font-medium"
            @click="
              executeAction('showUpdateOrResetPassword', { action: 'reset' })
            "
          >
            {{
              $t(
                'components.organizationUsersManagement.table.action.resetPassword'
              )
            }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="!data.is_active"
            class="font-medium"
            @click="executeAction('updateVerifyStatus', { action: 'verify' })"
          >
            {{
              $t('components.organizationUsersManagement.table.action.verify')
            }}
          </t-dropdown-item>
          <t-dropdown-item
            v-if="$user.is_superadmin"
            class="font-medium"
            @click="executeAction('forceLogout')"
          >
            {{
              $t(
                'components.organizationUsersManagement.table.action.forceLogout'
              )
            }}
          </t-dropdown-item>

          <!-- <t-dropdown-item variant="danger" @click="askConfirmation">
            Delete
          </t-dropdown-item> -->
        </div>
      </template>
    </t-dropdown>

    <!-- <ConfirmFirst
      :id="`confirmFirst-${data.id}`"
      :key="`confirmFirst-${data.id}`"
      :isLoading="isLoading"
      :title="`Delete Task?`"
      :subtitle="
        `All associated data will be deleted. This action cannot be undone.`
      "
      :criticalityLevel="`medium`"
      :criticalityTitle="`Please Type The Delation Code`"
      :criticalityPassword="`ILOVEOTORIDE`"
      :confirm-button-text="`Delete`"
      :confirm-button-variant="`warning-alt`"
      @confirm="executeAction('delete')"
      @cancel="handleCancellation"
    /> -->
    <t-modal
      :name="`holdOrDeactivateOrgUserAccount-${primaryKey}`"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="!holdOrDeactivate.isLoading"
      :clickToClose="!holdOrDeactivate.isLoading"
    >
      <section class="px-4 pt-4 text-2xl font-extrabold text-center">
        {{
          holdOrDeactivate.action === 'D'
            ? $t(
                'components.organizationUsersManagement.modal.holdDeactivate.deactivateAccount'
              )
            : $t(
                'components.organizationUsersManagement.modal.holdDeactivate.holdAccount'
              )
        }}
      </section>

      <div class="px-5 pt-5">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            @submit.prevent="
              handleSubmit(
                executeAction('submitHoldOrDeactivateOrgUserAccount', {
                  action: holdOrDeactivate.action,
                })
              )
            "
            enctype="multipart/form-data"
          >
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.organizationUsersManagement.modal.holdDeactivate.reason.title'
                )
              "
              :label="
                $t(
                  'components.organizationUsersManagement.modal.holdDeactivate.reason.title'
                )
              "
              :placeholder="
                $t(
                  'components.organizationUsersManagement.modal.holdDeactivate.reason.placeholder'
                )
              "
              :options="
                holdOrDeactivate.action === 'H'
                  ? holdOrDeactivate.reasons.hold
                  : holdOrDeactivate.reasons.deactivate
              "
              hide-search-box
              v-model="holdOrDeactivate.models.reason"
            />
            <AppInput
              v-model="holdOrDeactivate.models.remark"
              rules=""
              :label="
                $t(
                  'components.organizationUsersManagement.modal.holdDeactivate.remark'
                )
              "
              type="textarea"
              :name="
                $t(
                  'components.organizationUsersManagement.modal.holdDeactivate.remark'
                )
              "
            />
            <button
              type="submit"
              ref="holdOrDeactivateSubmitButtonRef"
              class="hidden"
            >
              Save
            </button>
          </form>
        </ValidationObserver>
      </div>

      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton
          :variant="`secondary`"
          :class="`w-1/2`"
          @click="executeAction('hideHoldOrDeactivateOrgUserAccount')"
        >
          {{ $t('components.stepNavigation.cancel') }}
        </AppButton>

        <AppButton
          :variant="`primary`"
          :class="`w-1/2`"
          :isLoading="holdOrDeactivate.isLoading"
          @click="$refs.holdOrDeactivateSubmitButtonRef.click()"
        >
          {{
            holdOrDeactivate.action === 'D'
              ? $t(
                  'components.organizationUsersManagement.modal.holdDeactivate.deactivate'
                )
              : $t(
                  'components.organizationUsersManagement.modal.holdDeactivate.hold'
                )
          }}
        </AppButton>
      </section>
    </t-modal>

    <t-modal
      ref="popup"
      :name="`updateOrResetPassword-${primaryKey}`"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="!isLoading"
      :clickToClose="!isLoading"
    >
      <section class="px-4 pt-4 text-2xl font-extrabold text-center">
        {{ password.action === 'change' ? 'Change' : 'Reset' }} password
      </section>

      <div v-if="password.action === 'change'" class="px-5 pt-5">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            @submit.prevent="
              handleSubmit(
                executeAction('doUpdateOrResetPassword', {
                  action: password.action,
                })
              )
            "
            enctype="multipart/form-data"
          >
            <AppInput
              v-model="password.models.old"
              rules="required|min:6"
              label="Current Password"
              type="password"
              name="old"
            />
            <AppInput
              v-model="password.models.new"
              rules="required|min:6"
              label="New Password"
              type="password"
              name="password"
            />
            <AppInput
              v-model="password.models.confirm"
              rules="confirmed:password"
              label="Confirm New Password"
              type="password"
              name="confirm"
            />
            <button type="submit" ref="passwordButton" class="hidden">
              Save
            </button>
          </form>
        </ValidationObserver>
      </div>

      <div v-if="password.action === 'reset'" class="px-5 pt-5">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            @submit.prevent="
              handleSubmit(
                executeAction('doUpdateOrResetPassword', {
                  action: password.action,
                })
              )
            "
            enctype="multipart/form-data"
          >
            <AppInput
              v-model="password.models.email"
              rules="required|email"
              label="Email address"
              type="email"
              :name="`email address`"
            />
            <!-- {{ password.models.email }} -->
            <button type="submit" ref="passwordButton" class="hidden">
              Save
            </button>
          </form>
        </ValidationObserver>
      </div>

      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton
          :variant="`secondary`"
          :class="`w-1/2`"
          @click="executeAction('hideUpdateOrResetPassword')"
        >
          Cancel
        </AppButton>

        <AppButton
          :variant="`primary`"
          :class="`w-1/2`"
          :isLoading="isLoading"
          @click="$refs.passwordButton.click()"
        >
          {{ password.action === 'change' ? 'Change' : 'Reset' }}
        </AppButton>
      </section>
    </t-modal>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
export default {
  name: 'UserMoreActionsDropdown',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    // ConfirmFirst: () => import('@/components/modals/ConfirmFirst'),
  },
  props: {
    primaryKey: {
      type: String,
      default: '',
    },

    data: {
      type: Object,
      required: false,
      default() {
        return {
          status: false,
        }
      },
    },
  },
  data: () => ({
    isLoading: false,
    password: {
      action: 'change',
      models: {
        old: '',
        new: '',
        confirm: '',
        email: '',
      },
    },
    holdOrDeactivate: {
      isLoading: false,
      action: 'H',
      models: {
        reason: null,
        remark: '',
      },
      reasons: {
        deactivate: [
          {
            text: 'Temporary Disabled because employee will be unavailable',
            value: 'TEMPORARY_DISABLE_EMPLOYEE_UNAVAILABLE',
          },
          {
            text: 'Permanent Disabled because employee was fired',
            value: 'TEMPORARY_DISABLE_EMPLOYEE_FIRED',
          },
          {
            text: 'Other',
            value: 'OTHER',
          },
        ],
        hold: [
          {
            text: 'Temporary Disabled because of suspicious activity',
            value: 'TEMPORARY_HOLD_SUSPICIOUS_ACTIVITY',
          },
          {
            text: 'Permanent Disabled because employee was Fired',
            value: 'PERMANENT_HOLD_EMPLOYEE_FIRED',
          },
          {
            text: 'Other',
            value: 'OTHER',
          },
        ],
      },
    },
  }),
  methods: {
    async executeAction(type, { action } = {}) {
      if (this.$acl.canNotUpdate('users')) {
        this.$notify(this.$acl.notAbleToToast())
        return
      }

      if (type === 'showUpdateOrResetPassword') {
        this.password.action = action
        this.$modal.show(`updateOrResetPassword-${this.primaryKey}`)
        return
      }

      if (type === 'hideUpdateOrResetPassword') {
        this.password.action = action
        this.$modal.hide(`updateOrResetPassword-${this.primaryKey}`)
        return
      }

      if (type === 'doUpdateOrResetPassword') {
        this.password.action = action
        this.isLoading = true
        const url =
          action === 'change'
            ? useEndpoints.user.org.changePassword(this.data.id)
            : useEndpoints.auth.resetPassword()
        const formData = new FormData()
        if (action === 'change') {
          formData.append('old_password', this.password.models.old)
          formData.append('new_password', this.password.models.new)
          formData.append('confirm_new_password', this.password.models.confirm)
        } else if (action === 'reset') {
          formData.append('email', this.password.models.email)
        }
        console.log(formData.getAll('email'))
        this.isLoading = true
        this.$http
          .post(url, formData)
          .then(({ data }) => {
            console.log(data)
            const toastTitle =
              action === 'change' ? 'Password Changed' : 'Password Reset'
            const toastMessage =
              action === 'change'
                ? 'Your password has been changed successfully.'
                : 'A password reset link has been sent to your email address.'
            const serverMessage = data?.message

            this.$notify(
              {
                group: 'bottomRight',
                type: 'success',
                title: toastTitle,
                text: serverMessage ?? toastMessage,
              },
              5000
            )

            this.password.models = {
              old: '',
              new: '',
              confirm: '',
              email: '',
            }

            this.$modal.hide(`updateOrResetPassword-${this.primaryKey}`)
          })
          .catch((err) => {
            console.log(err)
            const toastTitle =
              action === 'change' ? 'Password Change Failed' : 'Password Reset'
            const toastMessage =
              err.response?.data?.message ??
              'An error occurred while trying to change your password.'

            this.$notify(
              {
                group: 'bottomRight',
                type: 'error',
                title: toastTitle,
                text: toastMessage,
              },
              5000
            )
          })
          .finally(() => (this.isLoading = false))

        return
      }

      if (type === 'delete') {
        const endpoint = useEndpoints.task.delete(this.data.id)

        this.isLoading = true
        await this.$http
          .delete(endpoint)
          .then((res) => {
            console.log('delete', res.data)

            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Task has been deleted`,
              text: `The task has been deleted successfullhy`,
            })

            this.$emit('delete:success', res.data)
            this.$modal.hide(`confirmFirst-${this.data.id}`)
          })
          .catch((err) => {
            console.error(err)

            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Error Occured [${err.response.status}]`,
              text:
                err.response.data.message ??
                'Could not change task status, please try again.',
            })

            this.$emit('delete:failure', err)
          })
          .finally(() => {
            this.isLoading = false
          })
      }

      if (type === 'updateAccountStatus') {
        if (action === 'H' || action === 'D') {
          this.holdOrDeactivate.action = action
          this.$modal.show(`holdOrDeactivateOrgUserAccount-${this.primaryKey}`)
        } else {
          const endpoint = useEndpoints.user.org.updateStatus(this.data.id)
          const formData = new FormData()
          formData.append('user_status', action)

          this.isLoading = true
          await this.$http
            .put(endpoint, formData)
            .then((res) => {
              console.log('updateAccountStatus', res.data)

              this.$notify({
                group: 'bottomRight',
                type: 'success',
                title: `Account Status has been changed`,
                text: `The account status has been changed successfully`,
              })

              this.$emit('accountStatusUpdated')
            })
            .catch((err) => {
              console.error(err)

              this.$notify({
                group: 'bottomRight',
                type: 'error',
                title: `Error Occured [${err.response.status}]`,
                text:
                  err.response.data.message ??
                  'Could not change account status, please try again.',
              })
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      }
      if (type === 'updateVerifyStatus') {
        let isVerify
        let toastMessage
        if (action === 'verify') {
          isVerify = 'True'
          toastMessage = 'User has been verified successfully'
        }

        const endpoint = useEndpoints.user.org.update(this.data.id)
        const formData = new FormData()
        formData.append('is_active', isVerify)

        this.isLoading = true
        await this.$http
          .patch(endpoint, formData)
          .then((res) => {
            this.$store.dispatch('fsTable/fetchData')
            console.log('updateAccountStatus', res.data)

            this.$notify({
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: toastMessage,
            })
          })
          .catch((err) => {
            console.error(err)

            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Error Occured [${err.response.status}]`,
              text:
                err.response.data.message ??
                'Could not change  status, please try again.',
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      }
      if (type === 'submitHoldOrDeactivateOrgUserAccount') {
        this.holdOrDeactivate.isLoading = true
        const endpoint = useEndpoints.user.org.updateStatus(this.data.id)
        const formData = new FormData()
        formData.append('user_status', action)
        formData.append('status_reason', this.holdOrDeactivate.models.reason)
        if (this.holdOrDeactivate.models.remark) {
          formData.append('status_remark', this.holdOrDeactivate.models.remark)
        }

        await this.$http
          .put(endpoint, formData)
          .then((res) => {
            console.log('updateAccountStatus', res.data)

            this.$notify({
              group: 'bottomRight',
              type: 'success',
              title: `Account Status has been changed`,
              text: `The account status has been changed successfully`,
            })

            this.$emit('accountStatusUpdated')
          })
          .catch((err) => {
            console.error(err)

            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Error Occured [${err.response.status}]`,
              text:
                err.response.data.message ??
                'Could not change account status, please try again.',
            })
          })
          .finally(() => {
            this.holdOrDeactivate.isLoading = false
            this.$modal.hide(
              `holdOrDeactivateOrgUserAccount-${this.primaryKey}`
            )

            this.holdOrDeactivate.models.reason = null
            this.holdOrDeactivate.models.remark = ''
          })
      }
      if (type === 'hideHoldOrDeactivateOrgUserAccount') {
        this.$modal.hide(`holdOrDeactivateOrgUserAccount-${this.primaryKey}`)
        this.holdOrDeactivate.models.reason = null
        this.holdOrDeactivate.models.remark = ''
      }
      if (type === 'forceLogout') {
        const endpoint = useEndpoints.user.org.forceLogout(this.data.id)

        this.isLoading = true
        await this.$http.delete(endpoint).then(async () => {
          this.$notify({
            group: 'bottomRight',
            type: 'success',
            title: 'Success',
            text: 'Force Logout Successful',
          })
        })
      }
    },
    handleCancellation() {
      console.log('cancelled')
    },
  },
}
</script>

<style lang="scss" scoped></style>
